import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {SiteBase} from '../../site-base';
import {Router} from '@angular/router';
import {isNullOrUndefined} from 'util';
import {AppDataProvider} from '../../../common/app-data.provider';

@Component({
  selector: 'app-products-items',
  templateUrl: './products-items.component.html',
  styleUrls: ['./products-items.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductsItemsComponent extends SiteBase implements OnInit {

  t = {
    title: this.translate('products.events.title'),
    showdetails: this.translate('general.variables.btn_more_info'),
  };

  routerLinks = {
    1: '/stage1-pro',
    2: '/stage3-pro',
    3: '/science-and-education',
  };

  constructor(protected provider: AppDataProvider, protected sanitizer: DomSanitizer, private router: Router) {
    super(provider, sanitizer);
  }

  ngOnInit() {
  }


}
